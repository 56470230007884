import styles from "../stylesheets/Events.module.css"
import React, {useEffect, useCallback, useState} from "react"
import { archiveEvent, deleteEvent, fetchEvents } from "../actions/events"
import { EVENT_STATUS, MODE } from "../utils"
import { addErrorAlertWithAutoRemoval } from "../actions"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import ArchiveSymbol from "../assets/archive-box.svg"
import CerebroPopup from "./CerebroPopup"
import DeleteSymbol from "../assets/trashcan.svg"
import { fetchNodes } from "../actions/CreateEvent"
import { formatStartTime, getDurationInMinutes } from "./Events"
import StyleConstants from "../stylesheets/StyleConstants"
import AutoScheduledSymbol from "../assets/auto-scheduled.svg"
import {TablePagination} from "@mui/material";
import Cookies from "universal-cookie";
import {fetchEventTypes} from "../actions/event-types";

const isDateMatch = (event, startDate, endDate) => {
	return (!startDate || startDate.isSameOrBefore(new Date(event.startTime), 'day'))
		&& (!endDate || endDate.isSameOrAfter(new Date(event.startTime), 'day'))
}

const isStatusMatch = (status, statusFilter) => {
	return !statusFilter
		|| statusFilter === EVENT_STATUS.ALL
		|| status === statusFilter
}

const isTypeMatch = (type, mode) => {
	return mode === MODE.MLB ?
		type === MODE.MLB
		: type !== MODE.MLB
}

const selectEventsByFiltersSorted = (state, startDate, endDate, statusFilter, mode) => {
	return state.events.filter(event =>
		isDateMatch(event, startDate, endDate)
		&& isStatusMatch(event.status.name, statusFilter)
		&& isTypeMatch(event.type.name, mode)
	).sort(({ startTime: s1 }, { startTime: s2 }) =>
		new Date(s2).getTime() - new Date(s1).getTime())
}

const cookie = new Cookies()


export default function EventCards({ startDate, endDate, statusFilter, modeFilter }) {
	const dispatch = useDispatch()
	let history = useHistory()
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(parseInt(cookie.get("rowsPerPage"), 10) || 10)

	const events = useSelector(state =>
		selectEventsByFiltersSorted(state, startDate, endDate, statusFilter, modeFilter)
	)

	const eventTypes = useSelector(state => state.eventTypes)

	const nodes = useSelector(state => state.nodes)

	useEffect(() => {
		fetchNodes()(dispatch)
		fetchEventTypes()(dispatch)
	}, [dispatch])

	useEffect(() => {
		setPage(0)
	}, [statusFilter])

	useEffect(() => {
		if (!startDate || !endDate) {
			// Only fire off a request if there is a complete range
			return
		}
		if (!eventTypes) {
			return
		}

		let types = isTypeMatch(MODE.MLB, modeFilter) ? eventTypes.filter(et => et.name === 'MLB Game') : eventTypes.filter(et => et.name !== 'MLB Game')
		types = types.map(et => et.id)

		fetchEvents(startDate, endDate, types)(dispatch)
	}, [dispatch, startDate, endDate, eventTypes, modeFilter])

	const handleEventSelect = useCallback((e, status, eventId) => {
		if (e.target.name === 'eventActionButton') {
			e.preventDefault()
			e.stopPropagation()
		} else {
			if (EVENT_STATUS.PENDING !== status.name)
				history.push(`/event-detail/${eventId}`)
			else
				history.push(`/create/${eventId}`)
		}
	}, [history])


	const handleChangePage = (_, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		const newRowsPerPage = parseInt(event.target.value, 10)
		setRowsPerPage(newRowsPerPage)
		cookie.set("rowsPerPage", newRowsPerPage)
		setPage(0)
	}

	let  eventCards = rowsPerPage < 0 ? events : events
		.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)

	eventCards= eventCards.map(event =>
		<Event key={event.id}
			   event={event}
			   node={nodes.find(node => node.id === event.nodeId)}
			   action={<EventAction event={event} />}
			   handleClick={e =>
				   handleEventSelect(e, event.status, event.id)
			   }
			   history={history}
		/>
	)

	return (
		<div className={styles.cardContainer}>
			{eventCards?.length > 0 ? (
				<>
					{eventCards}
					<TablePagination
						style={{float: "right", marginRight: "10%"}}
						component="div"
						page={page}
						rowsPerPageOptions={[5, 10, 25, 50, {value: -1, label: "All"}]}
						count={events.length}
						onPageChange={handleChangePage}
						rowsPerPage={rowsPerPage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</>


			) : (
				<p>{"There are no scheduled channels during this date range :("}</p>
			)}

		</div>
	)
}

const EventAction = ({ event }) => {
	const dispatch = useDispatch()
	const { title, status } = event

	const handleDeleteEvent = useCallback(async e => {
		try {
			await deleteEvent(e)(dispatch)
		} catch (err) {
			addErrorAlertWithAutoRemoval(err.message)(dispatch)
		}
	}, [dispatch])

	const handleArchiveEvent = useCallback(async e => {
		try {
			await archiveEvent(e)(dispatch)
		} catch (err) {
			addErrorAlertWithAutoRemoval(err.message)(dispatch)
		}
	}, [dispatch])

	if (status?.name === EVENT_STATUS.ENDED) {
		const trigger = (
			<button className={styles.actionArchive} name="eventActionButton">
				<img src={ArchiveSymbol} alt="archive" name="eventActionButton"
					className={styles.actionIcon} width="20px" height="20px" />
			</button>
		)

		return (
			<CerebroPopup trigger={trigger} title="Archive Event"
				body={
					<span>
						You are about to archive the event <em>{title}</em>. The resources
						associated with this event will be removed, however, the event will
						still be accessible through the event summary screen. <strong>This
							action cannot be reversed</strong>.
					</span>
				}
				onClose={e => e && e.stopPropagation()}
				handleContinue={() => handleArchiveEvent(event)}
				continueStyle={styles.popupContinueArchive}
				continueContent={"Archive"}
			/>
		)
	} else if (status?.name === EVENT_STATUS.PENDING
		|| status?.name === EVENT_STATUS.SCHEDULED) {
		const trigger = (
			<button className={styles.actionDelete} name="eventActionButton">
				<img src={DeleteSymbol} alt="delete" name="eventActionButton"
					className={styles.actionIcon} width="20px" height="20px" />
			</button>
		)

		return (
			<CerebroPopup trigger={trigger} title="Delete Event"
				body={
					<span>
						You are about to delete the event <em>{title}</em>. The resources
						associated with this event will be removed and the event will
						no longer be accessible through the event summary screen. <strong>
							This action cannot be reversed</strong>.
					</span>
				}
				onClose={e => e && e.stopPropagation()}
				handleContinue={() => handleDeleteEvent(event)}
				continueStyle={styles.popupContinueDelete}
				continueContent={"Delete"}
			/>
		)
	}

	return null

}

const Event = ({ event, node, action, handleClick, history }) => {
	const {
		id, thumbnailUrl, title, status, startTime, endTime,
		programStartTime, autoScheduled, type
	} = event

	return (
		<div
			className={styles.card}
			id="event-row"
			onClick={e => handleClick(e, id, status, history)}
		>
			<div className={styles.cardContent}>

				<div className={styles.thumbnailContainer}>
					{<img src={thumbnailUrl} alt="event thumbnail" className={styles.thumbnail} />}
				</div>
				<div className={styles.cardItemContainer}>
					<div className={styles.cardItem}>
						<h1>Title</h1>
						<p>{title}</p>
						<div className={styles.cardItemSubscript}>{type?.name}</div>
					</div>
				</div>
				{<div className={styles.cardItemContainer}>
					<div className={styles.cardItem}>
						<h1>Status</h1>
						<p><Status status={status} autoScheduled={autoScheduled} className={styles.statusText} /></p>
						{node &&
							<div className={styles.cardItemSubscript}>{node.hostname}</div>
						}
					</div>
				</div>}
				<div className={styles.cardItemContainer}>
					{autoScheduled
						? <div className={styles.eventTimeContainer}>
							<div className={styles.startTime}>
								<h1>Stream Start Time</h1>
								<p>{formatStartTime(startTime)}</p>
							</div>
							<div className={styles.pgmStartTime}>
								<h1>Program Start Time</h1>
								<p>{formatStartTime(programStartTime)}</p>
							</div>
						</div>
						: <div className={styles.eventTimeContainer}>
							<div className={styles.cardItem}>
								<h1>Stream Start Time</h1>
								<p>{formatStartTime(startTime)}</p>
							</div>
						</div>
					}
				</div>
				<div className={styles.cardItemContainer}>
					<div className={styles.cardItem}>
						<h1>Stream Duration</h1>
						<p>{type.isLinear === true ? "00:00:00" : getDurationInMinutes(startTime, endTime)}</p>
					</div>
				</div>
				<div className={styles.actionContainer}>
					{action}
				</div>
			</div>

		</div>
	)
}
export const Status = ({ status, autoScheduled, className }) => {
	let style = {}
	const { green, red, orange, gunmetal } = StyleConstants.colors

	switch (status.name) {
		case EVENT_STATUS.LIVE:
			style.color = green
			break
		case EVENT_STATUS.ERROR:
			style.color = red
			break
		case EVENT_STATUS.SCHEDULED:
			style.color = orange
			break
		default:
			style.color = gunmetal
	}
	return (
		<span className={className} style={style}>
			{status.name}
			{autoScheduled &&
				<img src={AutoScheduledSymbol} alt="auto scheduled"
					className={styles.statusIcon} width="20px" height="20px" />
			}
		</span>
	)
}