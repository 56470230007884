import settings from "../../settings"
import { GAME_STATUS, PIPELINE_PROCESSOR_TYPE } from "../../utils"

export const gameStatusToMuiColor = status => {
	switch (status) {
		case GAME_STATUS.STARTING:
		case GAME_STATUS.ENDING:
			return "warning"
		case GAME_STATUS.RUNNING:
			return "success"
		case GAME_STATUS.ERROR:
			return "error"
		default:
			return "default"
	}
}

export function processorTypeToLabel(type, labelPreFix) {
	switch (type) {
		case PIPELINE_PROCESSOR_TYPE.BALLPARK_ENCODER:
			return "Ballpark Encoder"
		case PIPELINE_PROCESSOR_TYPE.INGRESS_MEDIA_CONNECT:
			return "Media Connect"
		case PIPELINE_PROCESSOR_TYPE.EGRESS_MEDIA_CONNECT:
			return `${labelPreFix} Partner Distribution`
		case PIPELINE_PROCESSOR_TYPE.PRE_PROCESS_ENCODER:
			return "Pre-Process Encoder"
		case PIPELINE_PROCESSOR_TYPE.CLOSED_CAPTION:
			return "Closed Captions"
		case PIPELINE_PROCESSOR_TYPE.POST_CLOSED_CAPTION_MEDIA_CONNECT:
			return "Post CC Media Connect"
		case PIPELINE_PROCESSOR_TYPE.POST_PROCESS_PARTNER_DELIVERY_ENCODER:
			return "Post-Process Partner Encoder"
		case PIPELINE_PROCESSOR_TYPE.POST_PROCESS_HLS_ENCODER:
			return "Post-Process HLS Encoder"
		default:
			return type
	}
}

export function deriveProcessorUri(uri, type) {
	switch (type) {
		case PIPELINE_PROCESSOR_TYPE.INGRESS_MEDIA_CONNECT:
		case PIPELINE_PROCESSOR_TYPE.EGRESS_MEDIA_CONNECT:
			return settings.MEDIA_CONNECT_ROOT.concat(uri)
		case PIPELINE_PROCESSOR_TYPE.POST_CLOSED_CAPTION_MEDIA_CONNECT:
			return settings.MEDIA_CONNECT_ROOT.concat(uri)
		case PIPELINE_PROCESSOR_TYPE.PRE_PROCESS_ENCODER:
			return settings.MEDIA_LIVE_ROOT.concat(uri)
		case PIPELINE_PROCESSOR_TYPE.POST_PROCESS_PARTNER_DELIVERY_ENCODER:
			return settings.MEDIA_LIVE_ROOT.concat(uri)
		case PIPELINE_PROCESSOR_TYPE.POST_PROCESS_HLS_ENCODER:
			return settings.MEDIA_LIVE_ROOT.concat(uri)
		case PIPELINE_PROCESSOR_TYPE.CLOSED_CAPTION:
			if (uri.includes("alta")) {
				return uri.replace("/alta_instances", "/gui/alta_instances")
			} else {
				return uri.replace("v2/instances/", "instances/monitor/instance/")
			}
		default:
			return uri
	}
}
