import StyleConstants from "../stylesheets/StyleConstants"
import {dateTimeEquals} from "./date-utils"

export const EVENT_STATUS = {
    ALL: 'All',
    PENDING: 'Pending',
    SCHEDULED: 'Scheduled',
    LIVE: 'Live',
    ERROR: 'Error',
    ENDED: 'Ended',
    ARCHIVED: 'Archived',
    DELETED: 'DELETED'
}

export const GAME_STATUS = {
    ALL: 'All',
    PROVISIONED: 'PROVISIONED',
    STANDBY: 'STANDBY',
    STARTING: 'STARTING',
    RUNNING: 'RUNNING',
    ENDED: 'ENDED',
    ENDING: 'ENDING',
    ERROR: 'ERROR'

}
export const gameStatusOrder = [GAME_STATUS.ERROR, GAME_STATUS.RUNNING, GAME_STATUS.PROVISIONED, GAME_STATUS.STARTING, GAME_STATUS.ENDING, GAME_STATUS.ENDED]


export const PIPELINE_PROCESSOR_TYPE = {
    BALLPARK_ENCODER: 'BALLPARK_ENCODER',
    INGRESS_MEDIA_CONNECT: 'INGRESS_MEDIA_CONNECT',
    EGRESS_MEDIA_CONNECT: 'EGRESS_MEDIA_CONNECT',
    POST_CLOSED_CAPTION_MEDIA_CONNECT: 'POST_CLOSED_CAPTION_MEDIA_CONNECT',
    PRE_PROCESS_ENCODER: 'PRE_PROCESS_ENCODER',
    POST_PROCESS_PARTNER_DELIVERY_ENCODER: 'POST_PROCESS_PARTNER_DELIVERY_ENCODER',
    POST_PROCESS_HLS_ENCODER: 'POST_PROCESS_HLS_ENCODER',
    CLOSED_CAPTION: 'CLOSED_CAPTION'
}

export const GAME_ASSET_TYPE = {
    PRE: 'PRE',
    POST: 'POST'
}

export const MODE = {
    ADHOC: 'Ad Hoc',
    MLB: 'MLB Game',
    MILB: 'MiLB'
}

export const ALERT_TYPE = {
    ERROR: 'ERROR',
    INFO: 'INFO',
    WARN: 'WARN'
}

export const PROVISION_TYPE = {
    PROVISION: 'PROVISION',
    CANCEL: 'CANCEL'
}

export const ALERT_TIMEOUT = 10000

export const DEFAULT_THUMBNAIL = 'https://storage.googleapis.com/cerebro-thumbnails-prod/new-adhoc-stream-placeholder.jpg'

export const fetchRetry = async (url, options, n) => {
    try {
        const response = await fetch(url, options)
        if (n < 1 || response.ok) return response

        console.log(`Retrying failed fetch ${n}`)
        await sleep(1000)
        return await fetchRetry(url, options, n - 1)
    } catch (err) {
        throw err
    }
}

export const sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const objectToArrayOfKeyValuePair = params => {
    return Object.entries(params)
        .map(([k, v]) => ({name: k, value: v}))
}

const isBlank = str => {
    return (!str || /^\s*$/.test(str))
}

export const getTwoDigitMonth = date => ("0" + (date?.getMonth() + 1)).slice(-2)
export const getTwoDigitDate = date => ("0" + date?.getDate()).slice(-2)

//So not to choke Conductor
export const stripSpecialChars = value => value.trim().replace(/[^a-zA-Z0-9\s]/g, '')
    .replace(/\s+/g, '-')


export const validateMetadata = (blurb, title, description) => {
    let response = {valid: true, invalidMetadata: {}}

    if (isBlank(blurb)) {
        response.valid = false
        response.invalidMetadata['blurb'] = 'This value cannot be blank'
    } else if (blurb?.length < 3) {
        response.valid = false
        response.invalidMetadata['blurb'] = 'This value must be at least 3 characters'
    }
    if (isBlank(title)) {
        response.valid = false
        response.invalidMetadata['title'] = 'This value cannot be blank'
    }
    if (isBlank(description)) {
        response.valid = false
        response.invalidMetadata['description'] = 'This value cannot be blank'
    }

    return response
}

export const gameStatusToColor = (status) => {
    switch (status) {
        case GAME_STATUS.STARTING:
        case GAME_STATUS.ENDING:
            return StyleConstants.colors.orange
        case GAME_STATUS.RUNNING:
            return StyleConstants.colors.green
        case GAME_STATUS.ERROR:
            return StyleConstants.colors.red
        default:
            return StyleConstants.colors.gunmetal
    }
}

export const gameEquals = (a, b) => {
    return a?.gamePk === b?.gamePk && dateTimeEquals(new Date(a?.gameDate), new Date(b?.gameDate))
}
